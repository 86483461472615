import { configureStore } from '@reduxjs/toolkit'
import menuitemsReducer from "./reducers/menuitems.reducer";
import adminReducer from "./reducers/admin.reducer";
// ...

export const store = configureStore({
    reducer: {
        menu : menuitemsReducer,
        admin : adminReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch